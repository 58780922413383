import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { QUERY } from '../URL'
import { fileToBase64 } from "utils"

const Query = {}
const URL = QUERY

Query.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page.filters)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Query.edit = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('PasaportNo', item.PasaportNo ?? '')
    data.append('YolcuEmail', item.YolcuEmail ?? '')
    data.append('Aciklama', item.Aciklama ?? '')
    data.append('Tel', item.YolcuTelefon ?? '')
    data.append('idStr',item.id)
    data.append('SilindiMi',"")
    data.append('SorguBitisTarihi', item.SorguBitisTarihi.format('YYYY-MM-DD'))
    if (item?.EkBelge?.file) {
        const base64 = await fileToBase64(item.EkBelge.file);
        data.append('EkBelge', base64.split(',')[1])
    }
    else data.append('EkBelge','')
    fetch({
        url: URL.EDIT ,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Query.add = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('PasaportNo', item.PasaportNo ?? '')
    data.append('YolcuEmail', item.YolcuEmail ?? '')
    data.append('Aciklama', item.Aciklama ?? '')
    data.append('YolcuTelefon', item.YolcuTelefon ?? '')
    data.append('SorguBitisTarihi', item.SorguBitisTarihi.format('YYYY-MM-DD'))
    if (item.EkBelge?.file) {
        let doc = await fileToBase64(item?.EkBelge?.file)
        doc = doc.split(",")[1]
        data.append('EkBelge', doc);
    }

    fetch({
        url: URL.ADD,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Query.delete = function (id, message) {
    if (!id) return;
    const formData = new FormData()
    formData.append('message', message ?? '')
    return fetch({
        url: URL.DELETE + id,
        method: 'delete',
        data: formData
    })
}

Query.listNotes = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page?.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    data.append('zamanlanmisSorguId', page?.zamanlanmisSorguId ?? -1)
    data.append('orderColumnAndDirection', page?.orderColumnAndDirection ?? 1)
    FilterManager(data, page.filters)

    return fetch({
        url: URL.LIST_NOTES,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Query.addNote = function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('ZamanlanmisSorguId', item?.ZamanlanmisSorguId ?? '')
    data.append('Note', item?.Note ?? '')


    return fetch({
        url: URL.ADD_NOTE,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

export default Query