import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { NOTE } from '../URL'
import { message } from "antd"
const Notes = {}

Notes.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('message', message)
    data.append('offset', page.current - 1)
    data.append('limit', page.pageSize)
    
    
    for (const [key, value] of data.entries()) {
        console.log(`${key}: ${value}`); 
    }
    FilterManager(data, page?.filters)

    for (const [key, value] of data.entries()) {
        console.log(`${key}: ${value}`); 
    }

    fetch({
        url: NOTE.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        })
}

Notes.types = async function (setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', 999)

    return fetch({
        url: NOTE.TYPE_LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        })
}



Notes.add = async function (data = {}, loading, cb) {
    if (loading) loading(true)
    const formData = new FormData()
    for (const key in data) {
        formData.append(`${key}`, data[key] ?? '')
    }
    return fetch({
        url: NOTE.ADD,
        method: 'post',
        data: formData
    })
        .then((r) => callbackManager(r, null, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        })
}
Notes.delete = async function (id, message) {
    if (!id) return;
    const formData = new FormData()
    formData.append('message', message ?? '')
    return fetch({
        url: URL.DELETE + id,
        method: 'delete',
        data: formData
    })
}


Notes.currentNotifList = async function (setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('orderColumnAndDirection', 1)
    data.append('offset', 0)
    data.append('limit', 999)

    return fetch({
        url: NOTE.CURRENT_NOTIF_LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        })
}

Notes.markAsRead = async function (data = {}, loading, cb) {
    if (loading) loading(true)
    const formData = new FormData()
    for (const key in data) {
        formData.append(`${key}`, data[key] ?? '')
    }
    return fetch({
        url: NOTE.MARK_AS_READ,
        method: 'put',
        data: formData
    })
        .then((r) => callbackManager(r, null, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        })
}

export default Notes