import { combineReducers } from 'redux';
import Auth from './Auth';
import Filters from './Filters';
import Theme from './Theme';

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    filters: Filters
});

export default reducers;