import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { APPROVAL } from '../URL'

const Approval = {}
const URL = APPROVAL

Approval.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)

    if (!(page.filters?.props?.field && page.filters?.props?.order)) {
        data.append('orderColumnAndDirection', 'Id desc')
    }

    FilterManager(data, page?.filters)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Approval.approve = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    FilterManager(data, item)
    data.set('OnayDurumId', 'KRQ6UcYKqmMHd%2f7Q34W22Q%3d%3d')

    fetch({
        url: URL.STATUS + item.Id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Approval.decline = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    const id = String(item.Id)
    const data = new FormData()
    delete item.Id
    FilterManager(data, item)
    data.set('OnayDurumId', 'aF2q8Rih%2b05PqJ%2bZIv2pGQ%3d%3d')
    fetch({
        url: URL.STATUS + id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

export default Approval