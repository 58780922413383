import { USER } from 'services/URL';
import {
	AUTH_TOKEN,
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
	SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
	SHOWMODAL,
	CANCELMODAL
} from '../constants/Auth';
const initState =  localStorage.getItem('AUTH-STATE') ? JSON.parse(localStorage.getItem('AUTH-STATE')) :{
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN),
	email: '',
	name: '',
	surname: '',
	roles: [],
	agreementModal: {
		showModal: false,
		email: null,
		password: null
	}
}

const auth = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			const authState = {
				...state,
				loading: false,
				redirect: '/',
				token: action.user.token,
				email: action.user.email,
				name: action.user.name,
				surname: action.user.surname,
				roles: action.user.userGroupId,
				userGroupName: action.user.userGroupName,
				userId: action.user.userId,
				agreementModal: {
					showModal: false,
					email: null,
					password: null
				}
			}
			localStorage.setItem('AUTH-STATE', JSON.stringify(authState))
			return {
				...state,
				loading: false,
				redirect: '/',
				token: action.user.token,
				email: action.user.email,
				name: action.user.name,
				surname: action.user.surname,
				roles: action.user.userGroupId,
				userGroupName: action.user.userGroupName,
				userId: action.user.userId,
				agreementModal: {
					showModal: false,
					email: null,
					password: null
				}
			}
		case SHOWMODAL:
			return {
				...state,
				agreementModal: action.showModal,
				loading: false
			}
		case CANCELMODAL:
			localStorage.removeItem(AUTH_TOKEN);
			return {
				...state,
				agreementModal: action.showModal,
				loading: false
			}
		case SHOW_AUTH_MESSAGE:
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE:
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				redirect: '/',
				loading: false
			}
		}
		case SIGNUP_SUCCESS: {
			return {
				...state,
				loading: false,
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		default:
			return state;
	}
}

export default auth