import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { EDIT_PROFILE }  from '../URL'

const Service = {}
const URL = EDIT_PROFILE

Service.update = function (data) {
    const formData = new FormData()
    formData.append('Email', data?.email)
    formData.append('Tel', data?.tel)
    formData.append('TcKimlikNo', isNaN(parseInt(data?.tcno)) ? 0 : data?.tcno)
    formData.append('Name', data?.name)
    formData.append('Surname', data?.surname)

    return fetch({
        url: URL.UPDATE + data.id,
        method: 'PUT',
        data: formData
    })
}

Service.get_info = function () {
    const data = new FormData()
    data.append('offset', 0)
    data.append('limit', 10)
    data.append('orderColumnAndDirection', 1)


    return fetch({
        url: URL.GET,
        method: 'POST',
        data
    })
}

export default Service