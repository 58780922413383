import fetch from 'auth/FetchInterceptor'

const AuthService = {}


AuthService.signIn = function (params) {
  var data = new FormData();
  data.append('email', params.email);
  data.append('password', params.password);

  return fetch({
    url: '/Authorazation/Login',
    method: 'post',
    data: data
  })
}

AuthService.signUp = function (params) {
  var data = new FormData();
  data.append('name', params.name);
  data.append('surname', params.surname);
  data.append('email', params.email);
  data.append('password', params.password);
  data.append('passwordAgain', params.passwordAgain);

  return fetch({
    url: '/Authorazation/Register',
    method: 'post',
    data: data
  })
}

export default AuthService