const initState = {
    pagination: {
        current: 1,
        pageSize: 10
    }
}

const Filters = (state = initState, action) => {
    switch (action.type) {
        case 'SETFILTERS':
            return {
                ...state,
                ...action.filters
            }
        case 'SETPAGINATON':
            return {
                ...state,
                pagination: {
                    current: action.pagination.current,
                    pageSize: action.pagination.pageSize
                }
            }
        default:
            return state;
    }
}

export default Filters