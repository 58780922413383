import React, { useEffect, useState } from 'react'

import { Button, List, Modal, notification } from "antd";
import GlobalService from "services/GlobalService";



export default function NotifsModal({ auth }) {




    const [notifList, setNotifList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const getNotifList = () => {
        GlobalService.Notes.currentNotifList().then(r => {
            
            const notifs = r?.Model ?? []
            setNotifList(notifs)
            setLoading(false)
            if (notifs.length > 0)
                setVisible(true)
        })
    }

    const onMarkAsRead = (notif) => {
        setLoading(true)
        GlobalService.Notes.markAsRead({ idStr: notif?.Id }).then(r => {
            
            if (r?.isSuccess) {
                getNotifList()
            }
        })
    }

    useEffect(() => {
        getNotifList()
    }, [])



    return (
        <Modal
            title="Bildirimler"
            visible={visible}
            footer={null}
            onCancel={() => { setVisible(false) }}
        >
            <List
                itemLayout="horizontal"
                dataSource={notifList}
                renderItem={item => (
                    <List.Item
                        style={{
                            backgroundColor: '#ebecfd',
                            borderRadius: '5px',
                            marginBottom: '10px',

                        }}
                        actions={[]}
                    >
                        <List.Item.Meta
                            title={"Not:"}
                        // description={"Hatirlatma Türü: " + item?.HatirlatmaTuru}
                        />
                        {item?.Not}
                        <div
                            style={{ display: 'flex', justifyContent: 'flex-end' }}

                        >

                            <Button
                                type='primary'
                                loading={loading}
                                onClick={() => onMarkAsRead(item)}>Okundu</Button>
                        </div>

                    </List.Item>
                )}
            />
        </Modal>
    )
}
