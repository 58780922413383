import tr_TR from 'antd/es/locale/tr_TR';
import enMsg from "../locales/en_US.json";

const TrLang = {
  antd: tr_TR,
  locale: 'tr-TR',
  messages: {
    ...enMsg
  },
};
export default TrLang;
