import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { COUNTRY } from '../URL'

const Country = {}
const URL = COUNTRY

Country.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page?.filters)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Country.edit = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('ReservedName', item.name)
    data.append('A2', item.A2)
    data.append('A3', item.A3)
    data.append('Sayisal', item.Sayisal)
    data.append('Uyruk', item.Uyruk)

    fetch({
        url: URL.EDIT + item.id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Country.add = function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('ReservedName', item.name)
    data.append('A2', item.A2)
    data.append('A3', item.A3)
    data.append('Sayisal', item.Sayisal)
    data.append('Uyruk', item.Uyruk)
    
    fetch({
        url: URL.ADD,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Country.delete = function (id, message) {
    if (!id) return;
    const formData = new FormData()
    formData.append('message', message ?? '')
    return fetch({
        url: URL.DELETE + id,
        method: 'delete',
        data: formData
    })
}

export default Country