import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated,
	agreementModal
} from "../actions/Auth";

import history from '../../history';
import FirebaseService from 'services/FirebaseService'
import AuthService from 'services/AuthService';
import GlobalService from 'services/GlobalService';

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload}) {
		try {
			const user = yield call(AuthService.signIn, { ...payload });
			if (!user.model.sozlesmeVarMi && user.isSuccess) {
				localStorage.setItem(AUTH_TOKEN, user.model.token);
				yield put(agreementModal({
					showModal: true,
					email: payload.email,
					password: payload.password
				}))
			} else if (user.model.sozlesmeVarMi && user.isSuccess) {
				const localData =  JSON.stringify({ name: user.model.name, surname: user.model.surname, email: user.model.email, roles: user.model?.userGroupId, userGroupName: user.model.userGroupName })
				localStorage.setItem('USER', localData)
				localStorage.setItem(AUTH_TOKEN, user.model.token);
				yield put(authenticated(user.model));	
			} else {
				yield put(showAuthMessage('Hatalı Giriş'));
			}
		} catch (error) {
			yield put(showAuthMessage(error.response?.data?.errorMessages[0]));
		}
	});
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
		try {
			const user = yield call(AuthService.signUp, { ...payload });
			if (user.isSuccess) {
				history.push("/auth/login-1")
				window.location.reload()
			} else {
				yield put(showAuthMessage('Hata'));
			}
		} catch (error) {
			yield put(showAuthMessage(error.response.data.errorMessages[0]));
		}
	}
	);
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(GlobalService.User.logout);
			// const signOutUser = true
			if (signOutUser) {
				localStorage.removeItem(AUTH_TOKEN);
				localStorage.removeItem('USER');
				localStorage.removeItem('AUTH-STATE');
				yield put(signOutSuccess(signOutUser));
				window.location.reload()
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}


export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithGoogleAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInWithFacebook)
  ]);
}
