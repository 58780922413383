import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { NOTIFICATION } from '../URL'

const Notification = {}
const URL = NOTIFICATION

Notification.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page?.filters)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Notification.by_user = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const offset = page.customOffset ?? (page.current * page.pageSize) - page.pageSize;
    const data = new FormData()
    data.append('orderColumnAndDirection', 1)
    data.append('offset', offset < 0 ? 0 : offset)
    data.append('limit', page.pageSize)

    fetch({
        url: URL.USER,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Notification.edit = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('SystemNotificationContent', item.SystemNotificationContent)
    data.append('MailContent', item.MailContent)
    data.append('MailTitle', item.MailTitle)
    data.append('EkKullaniciGruplari', Array.isArray(item.EkKullaniciGruplari) ? item.EkKullaniciGruplari.join("-") : "")
    data.append('KapsamDisiKullaniciGruplari', Array.isArray(item.KapsamDisiKullaniciGruplari) ? item.KapsamDisiKullaniciGruplari.join("-") : "")
    data.append('Immediately', item.Immediately)
    fetch({
        url: URL.EDIT + item.id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

export default Notification