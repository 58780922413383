import { callbackManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { ROLE } from '../URL'

const Role = {}
const URL = ROLE

Role.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('orderColumnAndDirection', 1)
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Role.privilegeList = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('orderColumnAndDirection', 1)
    data.append('offset', (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)

    fetch({
        url: URL.PRIVILEGE,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Role.edit = async function (role, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('ReservedName', role.name)

    fetch({
        url: URL.EDIT + role.id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Role.add = function (role, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('ReservedName', role.name)

    fetch({
        url: URL.ADD,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Role.addPrivilege = async function (role, setData, loading, cb) {
    if (loading) loading(true)

    try {
        for await (const id of role.PrivilegeId) {
            const data = new FormData()
            data.append('RoleId', role.RoleId)
            data.append('PrivilegeId', id)

            await fetch({
                url: "/Api/RolePrivilegeModel",
                method: 'post',
                data: data
            })
        }
        callbackManager('', setData, loading, cb)
    } catch (error) {
        if (loading) loading(false)
    }

}

Role.addUser = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('RoleId', item.RoleId ?? '')
    if (item.UserId) data.append('UserId', item.UserId);
    if (item.UserGroupId) data.append('UserGroupId', item.UserGroupId);

    fetch({
        url: "/Api/RoleObjModel",
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });

}

Role.getUsers = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('roleId', item.roleId)
    data.append('orderColumnAndDirection', 1)
    data.append('offset', (item.current * item.pageSize) - item.pageSize)
    data.append('limit', item.pageSize)

    fetch({
        url: "/Api/List/GetRoleObjListByRoleId",
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });

}

Role.deletePrivelege = function (userID, loading) {
    if (!userID) return;
    if (loading) loading(true)
    const formData = new FormData()
    formData.append('message', '')
    return fetch({
        url: "/Api/RolePrivilegeModel/" + userID,
        method: 'delete',
        data: formData
    })
        .then(() => { if (loading) loading(false); })
        .catch(() => { if (loading) loading(false); });
}

Role.delete = function (id, message) {
    if (!id) return;
    const formData = new FormData()
    formData.append('message', message ?? '')
    return fetch({
        url: URL.DELETE + id,
        method: 'delete',
        data: formData
    })
}

export default Role