import { callbackManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { PRIVILEGE } from '../URL'

const Privilege = {}
const URL = PRIVILEGE

Privilege.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('orderColumnAndDirection', 1)
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Privilege.edit = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('ReservedName', item.name)
    data.append('PrivligeControlStateId', 1)
    data.append('PrivilegeSubTypeId ', 0)
    data.append('PrivilegeTypeId', 4)

    fetch({
        url: URL.EDIT + item.id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Privilege.add = function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('ReservedName', item.name)
    data.append('PrivligeControlStateId', 2)
    data.append('PrivilegeTypeId', 4)
    
    fetch({
        url: URL.ADD,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Privilege.delete = function (id, message) {
    if (!id) return;
    const formData = new FormData()
    formData.append('message', message ?? '')
    return fetch({
        url: URL.DELETE + id,
        method: 'delete',
        data: formData
    })
}

export default Privilege