import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { MESSAGE } from '../URL'

const Message = {}
const URL = MESSAGE

Message.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('page', page.current - 1)
    data.append('size', page.pageSize)
    data.append('value', page.value ?? '')
    data.append('value2', page.value2 ?? '')
    data.append('commonAccessReference', '')
    data.append('flightIdentifier', page.ucusNumarasi ?? '')
    data.append('type', page.type ?? '')
    FilterManager(data, page.filters)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

export default Message