import User from './crudServices/User'
import Role from './crudServices/Role'
import Privilege from './crudServices/Privilege'
import UserGroupType from './crudServices/UserGroupType'
import UserGroup from './crudServices/UserGroup'
import Airline from './crudServices/Airline'
import Airport from './crudServices/Airport'
import Country from './crudServices/Country'
import TransactionType from './crudServices/TransactionType'
import Notification from './crudServices/Notification'
import Announcement from './crudServices/Announcement'
import Flight from './crudServices/Flight'
import Traveller from './crudServices/Traveller'
import TravellerTransaction from './crudServices/TravellerTransaction'
import Dashboard from './crudServices/Dashboard'
import TargetTraveller from './crudServices/TargetTraveller'
import Message from './crudServices/Message'
import Log from './crudServices/Log.js'
import Query from './crudServices/Query'
import Approval from './crudServices/Approval'
import RiskTraveller from './crudServices/RiskTraveller'
import FrequentFlyer from './crudServices/FrequentFlyer'
import FirstTimeTraveller from './crudServices/FirstTimeTraveller'
import UserProccess from './crudServices/UserProccess'
import TravellerContactInfo from './crudServices/TravellerContactInfo'
import EditProfile from './crudServices/EditProfileService'
import SupportRequest from './crudServices/SupportRequest'
import TimedApproval from './crudServices/TimedApproval'
import Notes from './crudServices/Notes'
import AddProcess from './crudServices/AddProcess'

const GlobalService = {
  FrequentFlyer,
  Query,
  Log,
  User,
  Role,
  Message,
  Privilege,
  UserGroupType,
  Airline,
  Airport,
  Country,
  UserGroup,
  TransactionType,
  Notification,
  Announcement,
  Flight,
  Traveller,
  TravellerTransaction,
  Dashboard,
  TargetTraveller,
  Approval,
  RiskTraveller,
  FirstTimeTraveller,
  UserProccess,
  TravellerContactInfo,
  EditProfile,
  SupportRequest,
  TimedApproval,
  Notes,
  AddProcess
}

function callbackManager(response, setData, loading, cb) {
  if (setData) setData(response)
  if (cb) cb(response)
  if (loading) loading(false)
  return response
}

function FilterManager(formData, filters) {
  if (formData && filters && typeof filters === "object") {
    if (filters.props && filters.props.field && filters.props.order) {
      formData.append(
        'orderColumnAndDirection',
        `${filters.props.field} ${filters.props.order === 'descend' ? 'desc' : ''}`
      )
    }
    for (const key in filters) {
      if (key !== 'props' && key !== 'pagination') {
        if (key && filters[key]) {
          
          formData.append(`${key}`, filters[key])
        }
      }

    }
  }
}

export function dataToFormData(data) {
  const formData = new FormData()
  for (const key in data) {
    formData.append(`${key}`, data[key] ?? '')
  }
  return formData
}


export { callbackManager, FilterManager }
export default GlobalService