import { callbackManager, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { TRANSACTION }  from '../URL'

const Transaction = {}
const URL = TRANSACTION

Transaction.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page?.filters)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Transaction.edit = async function (role, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('ReservedName', role.ReservedName)
    data.append('ReservedDescription', role.ReservedDescription)

    fetch({
        url: URL.EDIT + role.id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Transaction.add = function (role, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('ReservedName', role.ReservedName)
    data.append('ReservedDescription', role.ReservedDescription)

    fetch({
        url: URL.ADD,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

Transaction.delete = function (id, message) {
    if (!id) return;
    const formData = new FormData()
    formData.append('message', message ?? '')
    return fetch({
        url: URL.DELETE + id,
        method: 'delete',
        data: formData
    })
}

export default Transaction