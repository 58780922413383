import { useState, useEffect } from 'react';

const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState({
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setScreenSize({
                screenWidth: window.innerWidth,
                screenHeight: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        // return () => {
        //     window.removeEventListener('resize', handleResize);
        // };
    }, []);

    return screenSize;
};

export default useScreenSize;