import React, { useState } from 'react'

export const GlobalContext = React.createContext({})


export default function Providers({ children }) {

    const [globalData, setGlobalData] = useState({});


    return (
        <GlobalContext.Provider
            value={{
                globalData,
                setGlobalData
            }}>

            {children}

        </GlobalContext.Provider>
    )
}
