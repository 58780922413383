import { callbackManager, dataToFormData, FilterManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { TIMED_APPROVAL } from '../URL'

const TimedApproval = {}
const URL = TIMED_APPROVAL

TimedApproval.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page.customOffset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page.pageSize)
    FilterManager(data, page?.filters)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

TimedApproval.approve = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    const formData = dataToFormData(item)
    // data.set('OnayDurumId', 'KRQ6UcYKqmMHd%2f7Q34W22Q%3d%3d')

    return fetch({
        url: URL.STATUS + item.Id,
        method: 'put',
        data: formData
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

TimedApproval.decline = async function (item, setData, loading, cb) {
    if (loading) loading(true)
    const id = String(item.Id)
    const data = new FormData()
    delete item.Id
    FilterManager(data, item)
    // data.set('OnayDurumId', 'aF2q8Rih%2b05PqJ%2bZIv2pGQ%3d%3d')
    return fetch({
        url: URL.STATUS + id,
        method: 'put',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

TimedApproval.approveStatues = async function (setData, loading, cb) {
    return fetch({
        url: URL.APPROVE_STATUES,
        method: 'get',
    }).then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}


export default TimedApproval