import { FilterManager, callbackManager } from "services/GlobalService"
import fetch from 'auth/FetchInterceptor'
import { ADDPROCESS } from '../URL'
import { fileToBase64 } from "utils"

const AddProcess = {}
const URL = ADDPROCESS

AddProcess.list = async function (page, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('offset', page?.customOffset ?? page.offset ?? (page.current * page.pageSize) - page.pageSize)
    data.append('limit', page?.pageSize ?? page.limit ?? 10)
    data.append('orderColumnAndDirection', 1)
    FilterManager(data, page?.filters)

    fetch({
        url: URL.LIST,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        });
}

AddProcess.add = async  function (item, setData, loading, cb) {
    if (loading) loading(true)
    const data = new FormData()
    data.append('YolcuId', item.YolcuId);
    data.append('IslemTuru', item.IslemTuru ?? '')
    data.append('Aciklama', item.Aciklama ?? '')
    data.append('AktifMi', item.AktifMi ?? false)
    data.append('KomutaKontroleBildir', item.KomutaKontroleBildir ?? false)
    if (item.EkBelge?.file) {
        let doc = await fileToBase64(item?.EkBelge?.file)
        doc = doc.split(",")[1]
        data.append('EkBelge', doc);
    }else {
        data.append('EkBelge', '');
    }
    if (item.EkBelge2?.file) {
        let doc = await fileToBase64(item?.EkBelge2?.file)
        doc = doc.split(",")[1]
        data.append('EkBelge2', doc);
    }else {
        data.append('EkBelge2', '');
    }
    // data.append('EkBelge', item.EkBelge ?? '')
    // data.append('EkBelge2', item.EkBelge2 ?? '')

    fetch({
        url: URL.ADD,
        method: 'post',
        data: data
    })
        .then((r) => callbackManager(r, setData, loading, cb))
        .catch(() => {
            if (loading) loading(false)
        })
}





export default AddProcess